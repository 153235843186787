import { motion } from "framer-motion"
import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import PageFooter from "../../components/PageFooter"
import PageHeader from "../../components/PageHeader"
import Heading from "../../components/Heading"
import Cards from "../../components/Cards"
import Img from "gatsby-image"

function Access({ data }) {
  const headerImg = data.accessJson.headerImg.childImageSharp.fluid
  const title = data.accessJson.title
  const desc = data.accessJson.desc
  const image01 = data.accessJson.image01.childImageSharp.fluid
  const image02 = data.accessJson.image02.childImageSharp.fluid
  const items = data.accessJson.items

  return (
    <Layout>
      <Helmet>
        <title>
          Akses | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana
        </title>
        <meta
          name="description"
          content="Grand Wisata mengerti bahwa di era modernisasi seperti saat ini, mobilitas adalah hal penting. Untuk itu, Grand Wisata hadir dilokasi strategis dan dengan akses yang baik untuk memudahkan mobilitas anda."
        />
        <meta
          name="keywords"
          content="grand wisata bekasi,grand wisata,sinar mas land,properti,real estate,rumah"
        />
        <meta name="distribution" content="web" />
        <meta
          property="og:title"
          content="Akses | Grand Wisata Bekasi | Kota Mandiri Terintegrasi dan Terencana"
        />
        <meta
          property="og:description"
          content="Grand Wisata mengerti bahwa di era modernisasi seperti saat ini, mobilitas adalah hal penting. Untuk itu, Grand Wisata hadir dilokasi strategis dan dengan akses yang baik untuk memudahkan mobilitas anda."
        />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <PageHeader bg={headerImg}>Akses</PageHeader>
        <section className="container">
          <div className="row">
            <div className="col-12"></div>
            <Heading>{title}</Heading>
            <p>{desc}</p>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <Img fluid={image01}></Img>
            </div>
            <div className="col-sm-6">
              <Img fluid={image02}></Img>
            </div>
          </div>
          <Cards datas={items}></Cards>
        </section>
        <PageFooter />
      </motion.div>
    </Layout>
  )
}

export const query = graphql`
  {
    accessJson {
      desc
      title
      headerImg {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image01 {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image02 {
        childImageSharp {
          fluid(maxWidth: 6000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      items {
        img {
          childImageSharp {
            fluid(maxWidth: 6000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        link
        product
        type
      }
    }
  }
`

export default Access
